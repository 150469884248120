import { useCallback, useMemo } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import { NaicsOutDto } from '../../../swagger-types';
import { useGetNaics } from '../api/sti-api.hooks';
import { Autocomplete } from '@app/components/fields/autocomplete/Autocomplete';

interface Props<T extends FieldValues> {
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  name: FieldPath<T>;
  control: Control<T>;
  label?: string;
}

export const NaicsAutocomplete = <T extends FieldValues>({
  name,
  required,
  disabled,
  control,
  fullWidth,
  label = 'NAICS',
}: Props<T>) => {
  const { isLoading: loading, data: naicsRes } = useGetNaics();

  const data = useMemo(() => naicsRes?.result || [], [naicsRes?.result]);

  const renderOptionLabel = useCallback((option: NaicsOutDto) => {
    if (!option.naics) {
      return option.description;
    }
    return `${option.naics}: ${option.description}`;
  }, []);

  const options: NaicsOutDto[] = useMemo(() => (Array.isArray(data) ? data : []), [data]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete<NaicsOutDto>
          {...field}
          InputProps={{
            placeholder: 'NAICS Code',
            label,
            error: Boolean(error),
            required,
            inputSize: 'large',
          }}
          onChange={(event, value) => {
            if (value?.naics) {
              field.onChange(value);
            }
          }}
          getOptions={async () => options}
          loading={loading}
          fetchOnOpen
          browserAutocompleteOff
          getOptionLabel={renderOptionLabel}
          noOptionsText="NAICS Code Not Found"
          autoHighlight
          autoSelect
          fullWidth={fullWidth}
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={(options, { getOptionLabel, inputValue }) =>
            options.filter((option) => getOptionLabel(option).toLowerCase().includes(inputValue.toLowerCase()))
          }
        />
      )}
    />
  );
};
