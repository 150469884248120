import { Controller, useFormContext } from 'react-hook-form';
import { FC } from 'react';

import { ReactHookFormNumberField } from '@app/components/fields/react-hook-form-fields/ReactHookFormNumberField';
import { SubmissionOverviewProfileFormSchemaType } from '../schemas/submission.form';
import { NaicsAutocomplete } from '@app/domain/sti/components/NaicsAutocomplete';
import { OptionItem, Select } from '@app/components/select';
import { Input } from '@app/components/fields/input/Input';
import { US_STATES } from '../const/submission.const';
import { NcciOutDto, SubmissionOverviewOutDto } from '@app/swagger-types';
import { clsxm } from '@app/styles/clsxm';

interface Props {
  isUpdatingOverview: boolean;
  overviewData?: SubmissionOverviewOutDto;
  ncci?: NcciOutDto[];
}

export const SubmissionOverviewProfileEditForm: FC<Props> = ({ isUpdatingOverview, overviewData, ncci }) => {
  const form = useFormContext<SubmissionOverviewProfileFormSchemaType>();

  const uniqueFormattedGovClassOptions = overviewData?.payrollEntries
    ?.filter((item, index, self) => index === self.findIndex((t) => t.state === item.state && t.ncci === item.ncci))
    .map((entry) => {
      const classificationWording = ncci?.find((item) => item.ncci === entry.ncci)?.classificationWording || '';

      return `${entry.state} ${entry.ncci} - ${classificationWording}`;
    });

  return (
    <div className="grid grid-cols-1 items-start gap-4 4xl:grid-cols-[65fr_35fr] 4xl:gap-8">
      <div className="grid w-full grid-cols-1 gap-8">
        <div className={clsxm('grid w-full items-start gap-2', 'md:grid-cols-2 lg:grid-cols-4')}>
          <Controller
            control={form.control}
            name="domiciledState"
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                onChange={(e) => field.onChange(e)}
                disabled={isUpdatingOverview}
                error={Boolean(error)}
                variant="outlined"
                fullWidth
                inputProps={{
                  label: 'Dom State',
                  error: Boolean(error),
                  disabled: isUpdatingOverview,
                }}
              >
                {Object.entries(US_STATES).map(([value]) => (
                  <OptionItem key={value} value={value}>
                    {value}
                  </OptionItem>
                ))}
              </Select>
            )}
          />
          <Controller
            render={({ field }) => <Input {...field} disabled={isUpdatingOverview} label="States of Operation" />}
            control={form.control}
            name="operationStates"
          />
          <ReactHookFormNumberField
            control={form.control}
            thousandSeparator=","
            placeholder="N.NN"
            name="exModFactor"
            decimalScale={2}
            label="EMR"
          />
          <Controller
            render={({ field }) => <Input {...field} label="Haz Grade" disabled />}
            control={form.control}
            name="hazardGrade"
          />
        </div>
        <Controller
          control={form.control}
          name="governingClass"
          render={({ field, fieldState: { error } }) => (
            <Select
              {...field}
              inputProps={{ label: 'Gov Class', error: Boolean(error), disabled: isUpdatingOverview }}
              onChange={(e) => field.onChange(e)}
              classes={{ select: 'p-[15.5px]' }}
              error={Boolean(error)}
              variant="outlined"
              displayEmpty
              fullWidth
            >
              {uniqueFormattedGovClassOptions?.map((value) => (
                <OptionItem value={value} key={value}>
                  {value}
                </OptionItem>
              ))}
            </Select>
          )}
        />
        <NaicsAutocomplete name="naics" control={form.control} fullWidth disabled={isUpdatingOverview} />
      </div>
      <Controller
        control={form.control}
        name="operationalNotes"
        render={({ field }) => (
          <Input
            {...field}
            classes={{ root: 'size-full flex items-start justify-start' }}
            className="size-full min-h-[220px] 4xl:min-h-full"
            disabled={isUpdatingOverview}
            label="Operational Notes"
            variant="outlined"
            multiline
          />
        )}
      />
    </div>
  );
};
