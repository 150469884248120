export enum EQueryConfigName {
  // STI
  GET_VERSIONS_LIST = 'GET_VERSIONS_LIST',
  GET_CALC_STATES = 'GET_CALC_STATES',
  GET_NCCIS = 'GET_NCCIS',
  GET_NAICS = 'GET_NAICS',
  // Loss Ratio
  GET_LOSS_RATIOS = 'GET_LOSS_RATIOS',
  GET_LOSS_RATIO_FIELD_VALUE = 'GET_LOSS_RATIO_FIELD_VALUE',
  GET_LOSS_RATIO_SUMMARY = 'GET_LOSS_RATIO_SUMMARY',
  GET_LOSS_RATIO_SUMMARY_TOTAL = 'GET_LOSS_RATIO_SUMMARY_TOTAL',
  GET_LOSS_RATIO_DETAIL = 'GET_LOSS_RATIO_DETAIL',
  GET_LOSS_RATIO_DETAIL_TOTAL = 'GET_LOSS_RATIO_DETAIL_TOTAL',
  GET_LOSS_RATIO_CHART_DATA = 'GET_LOSS_RATIO_CHART_DATA',
  GET_LAST_EMAIL_ATTACHMENT_RECEIVED = 'GET_LAST_EMAIL_ATTACHMENT_RECEIVED',
  GET_LOSS_RATIO_UPDATED_AT = 'GET_LOSS_RATIO_UPDATED_AT',
  // Users
  GET_USER = 'GET_USER',
  GET_USERS = 'GET_USERS',
  GET_USERS_COMPACT = 'GET_USERS_COMPACT',
  GET_USER_POOLS = 'GET_USER_POOLS',
  GET_USER_ROLES = 'GET_USER_ROLES',
  GET_USER_STATUSES = 'GET_USER_STATUSES',
  GET_USER_CONFIRM_STATUSES = 'GET_USER_CONFIRM_STATUSES',
  GET_USER_SETTINGS = 'GET_USER_SETTINGS',
  // Submission
  GET_SUBMISSIONS = 'GET_SUBMISSIONS',
  GET_SUBMISSION_BY_ID = 'GET_SUBMISSION_BY_ID',
  GET_SUBMISSION_LOSS_REVIEW = 'GET_SUBMISSION_LOSS_REVIEW',
  GET_SUBMISSION_LOSS_SUMMARY = 'GET_SUBMISSION_LOSS_SUMMARY',
  GET_SUBMISSION_NAMED_INSUREDS = 'GET_SUBMISSION_NAMED_INSUREDS',
  GET_SUBMISSION_STI_FULL_PREFILL = 'GET_SUBMISSION_STI_FULL_PREFILL',
  GET_SUBMISSION_STI_AWS_PREFILL = 'GET_SUBMISSION_STI_AWS_PREFILL',
  GET_SUBMISSION_STI_OUTCOME = 'GET_SUBMISSION_STI_OUTCOME',
  GET_SUBMISSION_INSIGHTS = 'GET_SUBMISSION_INSIGHTS',
  GET_SUBMISSION_VERIFIED = 'GET_SUBMISSION_VERIFIED',
  GET_SUBMISSION_RISK_FACTORS = 'GET_SUBMISSION_RISK_FACTORS',
  GET_RISK_FACTORS_BY_SUBMISSION_ID = 'GET_RISK_FACTORS_BY_SUBMISSION_ID',
  GET_SUBMISSION_OVERVIEW = 'GET_SUBMISSION_OVERVIEW',
  // Claim
  GET_CLAIMS = 'GET_CLAIMS',
  GET_CLAIM_BY_ID = 'GET_CLAIM_BY_ID',
  GET_CLAIM_FIELD = 'GET_CLAIM_FIELD',
  GET_CLAIM_INFO_BROCHURE = 'GET_CLAIM_INFO_BROCHURE',
  GET_CLAIM_INFO_POSTER = 'GET_CLAIM_INFO_POSTER',
  GET_CLAIM_INFO_FORM = 'GET_CLAIM_INFO_FORM',
  GET_CLAIM_INFO_COMPLIANCE = 'GET_CLAIM_INFO_COMPLIANCE',
  GET_CLAIM_INFO_STATES = 'GET_CLAIM_INFO_STATES',
  GET_CLAIM_INFO_STATES_INFO = 'GET_CLAIM_INFO_STATES_INFO',
  // Data Exploration
  GET_DATA_EXPLORATION_TYPES = 'GET_DATA_EXPLORATION_TYPES',
  GET_DATA_EXPLORATION_PROMPTS = 'GET_DATA_EXPLORATION_PROMPTS',
}

export const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 10; /* 10 min */
