import { Box, Paper, Typography, CircularProgress } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useCallback, useEffect, useState, memo, FC } from 'react';
import { format } from 'date-fns';

import { useSubmissionCalculatorFormsContext } from '@app/domain/submission/contexts/SubmissionCalculatorFormsContext';
import { useCalculateSubmissionBySti } from '@app/domain/submission/api/submission.api.hooks';
import { CalcResponseState, FullCalcInDto, FullCalcOutDto } from '../types/calc.types';
import { ComparisonNcciStatesTable } from '../components/ComparisonNcciStatesTable';
import { ReactComponent as CSVIconSvg } from '../../../assets/icons/csv-icon.svg';
import { ReactComponent as PDFIconSvg } from '../../../assets/icons/pdf-icon.svg';
import { getStiValueFromStates, renderStiModelName } from '../utils/sti.utils';
import { ComparisonStatesTable } from '../components/ComparisonStatesTable';
import { IncidentStatsBlock } from '../components/IncidentStatsBlock';
import { createCsvFileFromFullCalcResDto } from '../utils/csv.utils';
import { ComparisonsTable } from '../components/ComparisonsTable';
import { convertISOToLocalTimeZone } from '@app/utils/date.utils';
import { createDownloadAsPDFLink } from '@app/pdf/pdf-api.utils';
import { CalcStatesTable } from '../components/CalcStatesTable';
import { Button } from '@app/components/buttons/button/Button';
import { IS_NOT_PROD_ENV } from '@app/environment/typed-env';
import { ESubmissionRiskFactor, StiResponseDetailErrors, SubmissionRiskFactorsOutDto } from '@app/swagger-types';
import { ErrorsTable } from '../components/ErrorsTable';
import { StiSlider } from '../components/StiSlider';
import { CalcTable } from '../components/CalcTable';
import { FORMAT } from '@app/constants/formats';
import { Routes } from '@app/constants/routes';
import { RiskFactorsTable } from '../components/RiskFactorsTable';

interface Props {
  submissionId: string;
  result: FullCalcOutDto;
  payload?: FullCalcInDto;
  riskFactors?: SubmissionRiskFactorsOutDto;
  originalCrDb?: number;
  responseId?: number;
  modelVersion?: string;
  errorMsg?: string;
  createdDate?: string;
  originalStates?: CalcResponseState[];
  warnings?: StiResponseDetailErrors[] | undefined;
  errors?: StiResponseDetailErrors[] | undefined;
  setIsEditing: (isEditing: boolean) => void;
}

export const SubmissionFullAssessmentResult: FC<Props> = memo(
  ({
    submissionId,
    originalStates,
    setIsEditing,
    modelVersion,
    originalCrDb,
    createdDate,
    responseId,
    warnings,
    errorMsg,
    payload,
    result,
    riskFactors,
    errors,
  }) => {
    const [stiValue, setStiValue] = useState(0);
    const [showStiSlider, setShowStiSlider] = useState(false);
    const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

    const handleDownloadCsv = useCallback(() => createCsvFileFromFullCalcResDto(result), [result]);

    const PDF_LINK = `${window.location.origin}${Routes.sti.fullCalcPdf}?responseId=${responseId}`;

    const handleDownloadPdf = useCallback(() => {
      window.location.href = createDownloadAsPDFLink(PDF_LINK);
      setIsDownloadingPDF(true);
      setTimeout(() => setIsDownloadingPDF(false), 6000);
    }, [PDF_LINK]);

    const { incidentStats, states, sti, confidence, stpPremium, totalExpClaims, finalPremium, assocDues, comparisons } =
      result;

    const DEFAULT_STI_VALUE = getStiValueFromStates(states);

    const shouldOverrideStiCrDb = stiValue !== DEFAULT_STI_VALUE;

    useEffect(() => {
      setStiValue(DEFAULT_STI_VALUE);
    }, [DEFAULT_STI_VALUE]);

    const { onChangeFullCalcResult, onChangeFullCalcErrors } = useSubmissionCalculatorFormsContext();

    const { mutateAsync: recalculate, isLoading } = useCalculateSubmissionBySti(
      submissionId,
      {
        originalCrDb: originalCrDb ?? DEFAULT_STI_VALUE,
        overrideCrDb: stiValue,
        originalStates: originalStates || states,
      },
      {
        onMutate: () => onChangeFullCalcErrors(null),
        onSuccess: (data) => {
          onChangeFullCalcResult(data);
          setShowStiSlider(false);
        },
        onError: (error) => {
          onChangeFullCalcResult(undefined);
          onChangeFullCalcErrors(error);
        },
      }
    );

    const { mutateAsync: resetToDefault, isLoading: isResetting } = useCalculateSubmissionBySti(
      submissionId,
      {},
      {
        onMutate: () => onChangeFullCalcErrors(null),
        onSuccess: (data) => {
          onChangeFullCalcResult(data);
          setShowStiSlider(false);
          if (data.data) {
            setStiValue(getStiValueFromStates(data.data.states));
          }
        },
        onError: (error) => {
          onChangeFullCalcResult(undefined);
          onChangeFullCalcErrors(error);
        },
      }
    );

    const handleRecalculate = useCallback(() => {
      if (payload) {
        recalculate(payload);
      }
    }, [payload, recalculate]);

    const handleReset = useCallback(() => {
      if (payload) {
        resetToDefault(payload);
      }
    }, [payload, resetToDefault]);

    const hasRisks = Boolean(
      riskFactors?.risks.filter(({ riskFactorStatus }) => riskFactorStatus !== ESubmissionRiskFactor.NOT_APPLICABLE)
        .length
    );

    return (
      <div className="flex w-full flex-col gap-8">
        <Paper className="flex items-center gap-4 px-6 py-[0.4rem] shadow-card">
          <Typography className="text-xxxl">SafeTier Rating</Typography>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              sx={{ '.MuiCircularProgress-circle': { strokeLinecap: 'round' } }}
              variant="determinate"
              thickness={5}
              value={sti}
              size={120}
            />
            <Box
              justifyContent="center"
              position="absolute"
              alignItems="center"
              display="flex"
              bottom={0}
              right={0}
              left={0}
              top={0}
            >
              <div className="flex flex-col items-center justify-center">
                <Typography className="mt-3 text-5xl font-medium leading-10 text-secondary">
                  {Math.round(sti)}
                </Typography>
                <Typography className="text-[8px] text-[#00000099]">STI</Typography>
              </div>
            </Box>
          </Box>
          <div className="flex items-center gap-2">
            <span className="text-xs text-[#00000061]">Rating Confidence:</span>
            <span className="text-xl font-medium">{confidence}%</span>
          </div>
          <div className="ml-auto flex items-center justify-between gap-4">
            {createdDate && (
              <Typography className="text-m text-[#00000099]">
                {format(convertISOToLocalTimeZone(createdDate), FORMAT.FULL_DATE_TIME_WITH_AMPM)}
              </Typography>
            )}
            <Button
              className="flex h-full max-h-[40px] items-center gap-2 rounded-md border-[1.5px] border-secondary bg-transparent px-4 py-3 font-medium text-[#000000DE]"
              onClick={() => setIsEditing(true)}
              size="small"
            >
              <EditNoteOutlinedIcon className="text-[#0000008A]" />
              Edit Rating Inputs
            </Button>
          </div>
        </Paper>
        <Paper className="p-8 shadow-card">
          <h2 className="mb-8 text-xxl text-[#000000DE]">Details</h2>
          <CalcTable
            overrideStiCrDb={shouldOverrideStiCrDb ? stiValue : undefined}
            originalCrDb={originalCrDb ?? DEFAULT_STI_VALUE}
            originalStates={originalStates || states}
            totalExpClaims={totalExpClaims}
            finalPremium={finalPremium}
            confidence={confidence}
            stpPremium={stpPremium}
            assocDues={assocDues}
            sti={sti}
          />
          <div className="pt-10">
            {showStiSlider ? (
              <div className="mb-6 flex justify-end">
                <div className="flex w-[650px] gap-2">
                  <StiSlider
                    defaultValue={DEFAULT_STI_VALUE}
                    min={DEFAULT_STI_VALUE - 100}
                    max={DEFAULT_STI_VALUE + 100}
                    isResetting={isResetting}
                    onChange={setStiValue}
                    onReset={handleReset}
                    disabled={isLoading}
                    value={stiValue}
                  />
                  <Button
                    disabled={!shouldOverrideStiCrDb}
                    onClick={handleRecalculate}
                    loading={isLoading}
                    className="w-fit"
                  >
                    Recalculate
                  </Button>
                </div>
              </div>
            ) : null}
            <CalcStatesTable
              overrideStiCrDb={shouldOverrideStiCrDb ? stiValue : undefined}
              originalCrDb={originalCrDb ?? DEFAULT_STI_VALUE}
              onClickStiCrDb={() => setShowStiSlider(true)}
              originalStates={originalStates}
              states={states}
            />
          </div>
          {comparisons?.company && comparisons?.expected ? (
            <div className="pt-10" style={{ width: 500 }}>
              <ComparisonsTable company={comparisons?.company} expected={comparisons?.expected} />
            </div>
          ) : null}
          {riskFactors && hasRisks ? (
            <div className="pt-10">
              <RiskFactorsTable riskFactors={riskFactors} />
            </div>
          ) : null}
          {comparisons?.states ? (
            <div className="pt-10">
              <ComparisonStatesTable states={comparisons.states} />
            </div>
          ) : null}
          {comparisons?.stateNcci ? (
            <div className="pt-10">
              <ComparisonNcciStatesTable states={comparisons.stateNcci} />
            </div>
          ) : null}
          <IncidentStatsBlock incidentStats={incidentStats} wrapperClassName="pt-10" />
          {errorMsg ? (
            <div className="mt-8 flex w-full justify-center">
              <Typography variant="body1" color="error" align="center">
                {errorMsg}
              </Typography>
            </div>
          ) : null}
          <div className="flex justify-center gap-2 pt-10">
            {IS_NOT_PROD_ENV ? (
              <a href={PDF_LINK} target="_blank" rel="noreferrer">
                <Button
                  variant="outlined"
                  className="h-[54px] w-60 rounded-sm border border-solid border-gray-700 py-3.5"
                >
                  Preview PDF file
                </Button>
              </a>
            ) : null}
            <Button
              variant="outlined"
              className="w-60 rounded-sm border border-solid border-gray-700 py-3.5"
              onClick={handleDownloadPdf}
              loading={isDownloadingPDF}
            >
              Download PDF <PDFIconSvg className="ml-2" />
            </Button>
            <Button
              variant="outlined"
              onClick={handleDownloadCsv}
              className="w-60 rounded-sm border border-solid border-gray-700 py-3.5"
            >
              Download CSV <CSVIconSvg className="ml-2" />
            </Button>
          </div>
          {modelVersion ? (
            <div className="flex justify-center pt-6">
              <Typography className="text-xs">
                Model version: {renderStiModelName(modelVersion, originalCrDb ?? DEFAULT_STI_VALUE)}
              </Typography>
            </div>
          ) : null}
        </Paper>
        {warnings && !isLoading ? (
          <Paper className="flex flex-col p-8 shadow-card">
            <div className="mb-8 flex items-center gap-2">
              <WarningRoundedIcon className="text-yellow-dark" />
              <h2 className="text-xxl text-[#000000DE]">Warnings</h2>
            </div>
            <ErrorsTable
              messageColumnLabel="Warning Details"
              containerClassName="p-0"
              codeColumnLabel="Code"
              errors={warnings}
              open
            />
          </Paper>
        ) : null}
        {errors && !isLoading ? (
          <Paper className="flex flex-col p-8 shadow-card">
            <div className="mb-8 flex items-center gap-2">
              <WarningRoundedIcon className="text-red-500" />
              <h2 className="text-xxl text-[#000000DE]">Errors</h2>
            </div>
            <ErrorsTable containerClassName="p-0" open errors={errors} onClose={undefined} />
          </Paper>
        ) : null}
      </div>
    );
  }
);
