import { z } from 'zod';

export const SubmissionFormSchema = z.object({
  namedInsuredId: z.string().min(1, 'Required'),
  effectiveDate: z.string().min(1, 'Required'),
  assignee: z.string(),
});

export type SubmissionFormSchemaType = z.infer<typeof SubmissionFormSchema>;

export const NamedInsuredFormSchema = z.object({
  name: z.string().min(1, 'Required'),
  fein: z
    .string()
    .refine((value) => value === '' || /^[0-9]{2}-[0-9]{7}$/.test(value), 'Invalid format. Use MM/DD/YYYY instead.'),
});

export type NamedInsuredFormSchemaType = z.infer<typeof NamedInsuredFormSchema>;

export const ProjectedPayrollSchema = z.object({
  state: z.string(),
  location: z.string(),
  payrollClassCode: z.object({
    id: z.number(),
    ncci: z.string(),
    classificationWording: z.string(),
  }),
  payrollAmount: z.number(),
});

export const InsuredLocationsSchema = z.object({
  location: z.string(),
  address: z.string(),
  city: z.string(),
  county: z.string(),
  state: z.string(),
  zipCode: z.string(),
});

export const IndividualsSchema = z.object({
  state: z.string(),
  location: z.string(),
  name: z.string(),
  dateOfBirth: z.string(),
  titleOrRelationship: z.string(),
  ownership: z.number(),
  duties: z.string(),
  includedOrExcluded: z.string(),
  classCode: z.string(),
  payrollRenumeration: z.number(),
});

export const SubmissionSendFormSchema = z.object({
  agency: z.string(),
  producerName: z.string(),
  csRepresentative: z.string(),
  officePhone: z.string(),
  emailAddress: z.string().email('Should be valid email'),
  insuredLegalName: z.string(),
  insuredOfficePhone: z.string(),
  insuredMobilePhone: z.string(),
  insuredMailingAddress: z.string().email('Should be valid email'),
  insuredCity: z.string(),
  insuredState: z.string(),
  insuredZipCode: z.string(),
  insuredYearsInBusiness: z.number(),
  insuredSIC: z.number(),
  insuredNAICS: z.number(),
  insuredWebsite: z.string(),
  insuredEmailAddress: z.string().email('Should be valid email'),
  insuredFEIN: z.number(),
  insuredNCCIRiskId: z.number(),
  insuredBusinessType: z.string(),
  piProposedEffectiveDate: z.string(),
  piProposedExpirationDate: z.string(),
  piEmployerLiability: z.string(),
  ciInspectionName: z.string(),
  ciInspectionOfficePhone: z.string(),
  ciInspectionMobile: z.string(),
  ciInspectionEmail: z.string().email('Should be valid email'),
  ciAccountingName: z.string(),
  ciAccountingOfficePhone: z.string(),
  ciAccountingMobile: z.string(),
  ciAccountingEmail: z.string().email('Should be valid email'),
  ciClaimsName: z.string(),
  ciClaimsOfficePhone: z.string(),
  ciClaimsMobile: z.string(),
  ciClaimsEmail: z.string().email('Should be valid email'),
  individuals: z.array(IndividualsSchema),
  insuredLocations: z.array(InsuredLocationsSchema),
  projectedPayroll: z.array(ProjectedPayrollSchema),
  natureOfBusiness: z.string(),
  exModRiskName: z.string(),
  exModFactor: z.number(),
  exModRatingEffectiveDate: z.string(),
  exModState: z.string(),
  exModNCCIRiskId: z.string(),
});

export type SubmissionSendFormSchemaType = z.infer<typeof SubmissionSendFormSchema>;

export const SubmissionOverviewProfileFormSchema = z.object({
  domiciledState: z.string(),
  operationStates: z.string(),
  exModFactor: z.number(),
  hazardGrade: z.string(),
  governingClass: z.string(),
  naics: z.object({ id: z.number(), naics: z.string(), description: z.string() }).nullable(),
  operationalNotes: z.string(),
});

export type SubmissionOverviewProfileFormSchemaType = z.infer<typeof SubmissionOverviewProfileFormSchema>;

export const SubmissionOverviewDetailsFormSchema = z.object({
  agencyName: z.string(),
  producerName: z.string(),
  csRepresentativeName: z.string(),
  agencyPhone: z.string(),
  agencyEmail: z.string().email('Should be valid email'),
});

export type SubmissionOverviewDetailsFormSchemaType = z.infer<typeof SubmissionOverviewDetailsFormSchema>;
