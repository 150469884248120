import { EUserStatus, EUserConfirmationStatus, UserOutCompactDto } from '@app/swagger-types';
import { capitalize, upperCase } from 'lodash';

export const renderFriendlyUserConfirmationStatusLabel = (status: EUserConfirmationStatus) => {
  switch (status) {
    case EUserConfirmationStatus.CONFIRMED:
      return 'Confirmed';
    case EUserConfirmationStatus.FORCE_CHANGE_PASSWORD:
      return 'Force change password';
    case EUserConfirmationStatus.RESET_REQUIRED:
      return 'Reset required';
    case EUserConfirmationStatus.UNCONFIRMED:
      return 'Unconfirmed';
    case EUserConfirmationStatus.ARCHIVED:
      return 'Archived';
    case EUserConfirmationStatus.COMPROMISED:
      return 'Compromised';
    default:
      return status;
  }
};

export const getUserConfirmationStatusColor = (status: EUserConfirmationStatus) => {
  switch (status) {
    case EUserConfirmationStatus.CONFIRMED:
      return '#2E7D32';
    case EUserConfirmationStatus.RESET_REQUIRED:
    case EUserConfirmationStatus.ARCHIVED:
    case EUserConfirmationStatus.COMPROMISED:
      return '#737373';
    case EUserConfirmationStatus.UNCONFIRMED:
    case EUserConfirmationStatus.FORCE_CHANGE_PASSWORD:
      return '#D32F2F';
    default:
      return '#737373';
  }
};

export const renderFriendlyUserStatusLabel = (status: EUserStatus) => {
  switch (status) {
    case EUserStatus.DISABLED:
      return 'Disabled';
    case EUserStatus.ENABLED:
      return 'Enabled';
    default:
      return status;
  }
};

export const renderUserStatusColor = (status: EUserStatus) => {
  switch (status) {
    case EUserStatus.DISABLED:
      return '#E60000';
    case EUserStatus.ENABLED:
      return '#1F2937';
    default:
      return '#1F2937';
  }
};

export const renderFriendlyUserPoolName = (userPoolName: string) => {
  if (userPoolName === 'usisa') {
    return upperCase(userPoolName);
  }
  return capitalize(userPoolName);
};

export const renderUserCompactName = ({ firstName = '', lastName = '', username }: UserOutCompactDto) =>
  firstName || lastName ? `${firstName} ${lastName}` : username;
