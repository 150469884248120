import { FC } from 'react';

import { SubmissionOverviewEstimatedAnnualPayroll } from '../components/SubmissionOverviewEstimatedAnnualPayroll';
import { useGetSubmissionByIdQuery, useGetSubmissionOverviewQuery } from '../api/submission.api.hooks';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { SubmissionOverviewDetails } from '../components/SubmissionOverviewDetails';
import { SubmissionOverviewProfile } from '../components/SubmissionOverviewProfile';

interface Props {
  submissionId: string;
}

export const SubmissionOverviewTab: FC<Props> = ({ submissionId }) => {
  const { naics, ncci } = useSubmissionCalculatorFormsContext();
  const { data } = useGetSubmissionByIdQuery(submissionId);

  const { data: overviewData } = useGetSubmissionOverviewQuery(submissionId);

  const { fullCalcResult } = useSubmissionCalculatorFormsContext();

  return (
    <div className="grid grid-cols-[7fr_3fr] gap-2">
      <div className="flex flex-col gap-2">
        <SubmissionOverviewProfile
          hasFullCalcData={Boolean(fullCalcResult)}
          overviewData={overviewData}
          naics={naics?.result || []}
          submissionId={submissionId}
          ncci={ncci?.result || []}
        />
        <SubmissionOverviewEstimatedAnnualPayroll ncci={ncci?.result || []} overviewData={overviewData} />
      </div>
      <SubmissionOverviewDetails overviewData={overviewData} data={data} submissionId={submissionId} />
    </div>
  );
};
