import { DefaultValues } from 'react-hook-form';

import {
  SubmissionOverviewDetailsFormSchemaType,
  SubmissionOverviewProfileFormSchemaType,
} from '../schemas/submission.form';

export const submissionOverviewProfileFormDefaultValues: DefaultValues<SubmissionOverviewProfileFormSchemaType> = {
  domiciledState: '',
  operationStates: '',
  exModFactor: 0,
  hazardGrade: '',
  governingClass: '',
  naics: null,
  operationalNotes: '',
};

export const submissionOverviewDetailsFormDefaultValues: DefaultValues<SubmissionOverviewDetailsFormSchemaType> = {
  agencyName: '',
  producerName: '',
  csRepresentativeName: '',
  agencyPhone: '',
  agencyEmail: '',
};
