import { FormProvider } from 'react-hook-form';
import { Card } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { SubmissionOverviewProfileEditForm } from './SubmissionOverviewProfileEditForm';
import { SubmissionOverviewProfileView } from './SubmissionOverviewProfileView';
import { useSubmissionOverviewProfileForm } from '../hooks/useSubmissionOverviewForm';
import { useHandler } from '@app/hooks/useHandler.hook';
import { NaicsOutDto, NcciOutDto, SubmissionOverviewOutDto } from '@app/swagger-types';
import { useUpdateSubmissionOverviewMutation } from '../api/submission.api.hooks';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { GOVERNING_CLASS_REGEXP, GOVERNING_CLASS_VALUE_REGEXP } from '../const/submission.const';
import { useConfirmSubmissionOverviewChangeDialog } from '../modals/ConfirmSubmissionOverviewChangeDialog';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';

interface Props {
  overviewData?: SubmissionOverviewOutDto;
  submissionId: string;
  naics: NaicsOutDto[];
  ncci: NcciOutDto[];
  hasFullCalcData: boolean;
}

export const SubmissionOverviewProfile: FC<Props> = ({ naics, submissionId, overviewData, ncci, hasFullCalcData }) => {
  const queryClient = useQueryClient();

  const { onChangeFullCalcResult, onChangeAwsCalcResult } = useSubmissionCalculatorFormsContext();

  const confirmDialog = useConfirmSubmissionOverviewChangeDialog();

  const { reset, getValues, ...form } = useSubmissionOverviewProfileForm();

  const { mutateAsync: updateOverview, isLoading: isUpdatingOverview } = useUpdateSubmissionOverviewMutation(
    submissionId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_OVERVIEW, submissionId] });
        queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_STI_OUTCOME, submissionId] });

        onChangeFullCalcResult(undefined);
        onChangeAwsCalcResult(undefined);
      },
      onSettled: () => setDetailsEditing(false),
    }
  );

  const [isDetailsEditing, setDetailsEditing] = useState(false);

  const handleStartEditing = useHandler(() => setDetailsEditing(true));

  const handleDiscardChanges = useHandler(() => {
    if (overviewData) {
      const naicsItem = naics.find((item) => item.naics === overviewData.naics) || null;

      let governingClass: string = '';

      const match = overviewData?.governingClass?.match(GOVERNING_CLASS_REGEXP);

      if (match) {
        const stateName = match[1];
        const ncciCode = match[2];

        const ncciItem = ncci.find((item) => item.ncci === ncciCode);

        governingClass = `${stateName} ${ncciCode} - ${ncciItem?.classificationWording}`;
      }

      reset({
        domiciledState: overviewData.domiciledState || '',
        operationStates: overviewData.operationStates || '',
        exModFactor: overviewData.exModFactor || 0,
        hazardGrade: overviewData.hazardGrade || '',
        governingClass,
        naics: naicsItem || null,
        operationalNotes: overviewData.operationalNotes || '',
      });
    }

    setDetailsEditing(false);
  });

  const handleSaveChanges = useHandler(async () => {
    const naicsValue: null | NaicsOutDto = getValues('naics');

    const naicsItem = naics.find((item) => item.naics === naicsValue?.naics)?.naics || '';

    const { domiciledState, operationStates, operationalNotes, exModFactor, governingClass } = getValues();

    let governingClassFormatted: string = '';

    const match = governingClass.match(GOVERNING_CLASS_VALUE_REGEXP);

    if (match) {
      governingClassFormatted = match[0].replace(/\s/g, '');
    }

    if (hasFullCalcData && (exModFactor !== overviewData?.exModFactor || naicsValue?.naics !== overviewData?.naics)) {
      confirmDialog.open({
        onCancel: handleDiscardChanges,
        onConfirm: () => {
          updateOverview({
            domiciledState: domiciledState || '',
            operationStates: operationStates || '',
            exModFactor: exModFactor || 0,
            operationalNotes: operationalNotes || '',
            governingClassCode: governingClassFormatted || '',
            naics: naicsItem,
          });
        },
      });
    } else {
      await updateOverview({
        domiciledState: domiciledState || '',
        operationStates: operationStates || '',
        exModFactor: exModFactor || 0,
        operationalNotes: operationalNotes || '',
        governingClassCode: governingClassFormatted || '',
        naics: naicsItem,
      });
    }
  });

  useEffect(() => {
    if (overviewData) {
      const naicsItem = naics.find((item) => item.naics === overviewData.naics) || null;

      let governingClass: string = '';

      const match = overviewData?.governingClass?.match(GOVERNING_CLASS_REGEXP);

      if (match) {
        const stateName = match[1];
        const ncciCode = match[2];

        const ncciItem = ncci.find((item) => item.ncci === ncciCode);

        governingClass = `${stateName} ${ncciCode} - ${ncciItem?.classificationWording}`;
      }

      reset({
        domiciledState: overviewData.domiciledState || '',
        operationStates: overviewData.operationStates || '',
        exModFactor: overviewData.exModFactor || 0,
        hazardGrade: overviewData.hazardGrade || '',
        governingClass,
        naics: naicsItem || null,
        operationalNotes: overviewData.operationalNotes || '',
      });
    }
  }, [reset, overviewData, naics, ncci]);

  return (
    <Card className="flex w-full flex-col gap-2.5 overflow-x-auto rounded-lg border border-[#0000001F] bg-white px-6 py-10 shadow-card">
      <div className="flex items-center justify-between">
        <h2 className="text-xxxl">Submission Profile</h2>
        {isDetailsEditing ? (
          <div className="flex items-center gap-2">
            <Tooltip placement="top" title="Discard Changes">
              <IconButton onClick={handleDiscardChanges} className="text-gray-500" size="large">
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={!form.formState.isDirty ? 'You need to make changes to save' : 'Save Changes'}
              placement="top"
            >
              <IconButton
                disabled={!form.formState.isDirty}
                onClick={handleSaveChanges}
                className="text-gray-500"
                size="large"
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <IconButton onClick={handleStartEditing} className="text-gray-500" size="large">
            <EditNoteIcon />
          </IconButton>
        )}
      </div>
      <FormProvider reset={reset} getValues={getValues} {...form}>
        {isDetailsEditing ? (
          <SubmissionOverviewProfileEditForm
            isUpdatingOverview={isUpdatingOverview}
            overviewData={overviewData}
            ncci={ncci}
          />
        ) : (
          <SubmissionOverviewProfileView naics={naics} overviewData={overviewData} />
        )}
      </FormProvider>
    </Card>
  );
};
