import { UseFormReset } from 'react-hook-form';
import { useEffect } from 'react';

import { formatStiAwsPrefilledValues, formatStiFullPrefilledValues } from '@app/domain/sti/utils/form.utils';
import { awsCalcDefaultValues } from '@app/domain/sti/forms/calc-default-values';
import { AwsCalcValidationSchema, FullCalcValidationSchemaType } from '@app/domain/sti/forms/calc.form';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { AwsCalcOutDtoResponse, FullCalcOutDtoResponse } from '@app/domain/sti/types/calc.types';
import { EOutcomeType, SubmissionGiCalcOutDto } from '@app/swagger-types';
import { ESubmissionSafeTierRatingTab } from '../widgets/SubmissionTabs';
import {
  useGetSubmissionStiFullPrefill,
  useGetSubmissionStiAwsPrefill,
  useGetSubmissionStiOutcome,
} from '../api/submission.api.hooks';
import { useHandler } from '@app/hooks/useHandler.hook';

const LAST_MILE_NCCI_VALUES = ['7230', '7231'];

export const useSubmissionCalcPrefill = (
  submissionId: string,
  outcomeType: EOutcomeType,
  reset: { full: UseFormReset<FullCalcValidationSchemaType>; aws: UseFormReset<AwsCalcValidationSchema> },
  values: { full: FullCalcValidationSchemaType; aws: AwsCalcValidationSchema }
) => {
  const { data: fullPrefillData, isLoading: isFullPrefillLoading } = useGetSubmissionStiFullPrefill(submissionId, {
    refetchOnMount: 'always',
    cacheTime: 0,
  });

  const { data: awsPrefillData } = useGetSubmissionStiAwsPrefill(submissionId, {
    refetchOnMount: 'always',
    cacheTime: 0,
    enabled: !isFullPrefillLoading,
  });

  const { data: outcomeFullData, isFetching: isOutcomeFullFetching } = useGetSubmissionStiOutcome(
    submissionId,
    EOutcomeType.FULL_CALC,
    {
      enabled: Boolean(outcomeType === EOutcomeType.FULL_CALC && submissionId),
      refetchOnMount: 'always',
      cacheTime: 0,
    }
  );

  const { data: outcomeAwsData, isFetching: isOutcomeAwsFetching } = useGetSubmissionStiOutcome(
    submissionId,
    EOutcomeType.AWS_CALC,
    {
      enabled: Boolean(outcomeType === EOutcomeType.AWS_CALC && submissionId),
      refetchOnMount: 'always',
      cacheTime: 0,
    }
  );

  const { data: outcomeGiData, isFetching: isOutcomeGiDataFetching } = useGetSubmissionStiOutcome(
    submissionId,
    EOutcomeType.GI_CALC,
    {
      refetchOnMount: 'always',
      cacheTime: 0,
      enabled: Boolean(submissionId) && outcomeAwsData?.responseType === EOutcomeType.AWS_CALC,
    }
  );

  const { onChangeFullCalcResult, onChangeAwsCalcResult, naics, ncci, setCalcTab, onChangeAwsGiData } =
    useSubmissionCalculatorFormsContext();

  const onResetFullValues = useHandler(() => {
    if (fullPrefillData) {
      reset.full(formatStiFullPrefilledValues(fullPrefillData, naics?.result || [], ncci?.result || []));
    } else {
      reset.full();
    }
  });

  useEffect(() => {
    if (outcomeFullData && outcomeFullData.responseType === EOutcomeType.FULL_CALC) {
      onChangeFullCalcResult(outcomeFullData as FullCalcOutDtoResponse);
    }

    if (outcomeAwsData && outcomeAwsData.responseType === EOutcomeType.AWS_CALC) {
      onChangeAwsCalcResult(outcomeAwsData as AwsCalcOutDtoResponse);
      onChangeAwsGiData(outcomeGiData as SubmissionGiCalcOutDto);
    }
  }, [
    onChangeAwsCalcResult,
    onChangeAwsGiData,
    onChangeFullCalcResult,
    outcomeAwsData,
    outcomeFullData,
    outcomeGiData,
  ]);

  useEffect(() => {
    const isLastMileTab = fullPrefillData?.payrollEntries?.some((entry) =>
      entry.ncci ? LAST_MILE_NCCI_VALUES.includes(entry.ncci) : false
    );

    if (isLastMileTab) {
      setCalcTab(ESubmissionSafeTierRatingTab.LAST_MILE);
    }
  }, [fullPrefillData?.payrollEntries, setCalcTab]);

  useEffect(() => {
    if (awsPrefillData && JSON.stringify(values.aws) === JSON.stringify(awsCalcDefaultValues)) {
      reset.aws(formatStiAwsPrefilledValues(awsPrefillData, ncci?.result || []));
    }
  }, [awsPrefillData, ncci?.result, reset, values.aws]);

  useEffect(() => {
    if (fullPrefillData) {
      onResetFullValues();
    }
  }, [fullPrefillData, onResetFullValues]);

  return {
    isOutcomeFetching: isOutcomeFullFetching || isOutcomeAwsFetching,
    isOutcomeGiDataFetching,
    onResetFullValues,
  };
};
