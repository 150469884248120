import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';

import { NcciOutDto, SubmissionOverviewOutDto } from '@app/swagger-types';
import { renderDollarAmount } from '@app/utils/currency/currency.utils';
import { clsxm } from '@app/styles/clsxm';

interface Props {
  overviewData?: SubmissionOverviewOutDto;
  ncci: NcciOutDto[];
}

export const SubmissionOverviewPayrollTable: FC<Props> = ({ overviewData, ncci }) => {
  const noData = !overviewData?.payrollEntries || overviewData?.payrollEntries?.length === 0;

  const renderedPayrollEntries = overviewData?.payrollEntries?.map((row, index) => {
    const ncciItem = ncci.find((item) => item.ncci === row.ncci);

    return (
      <TableRow key={index}>
        <TableCell>{row.state}</TableCell>
        <TableCell>{ncciItem ? `${ncciItem.ncci} - ${ncciItem.classificationWording}` : '-'}</TableCell>
        <TableCell className="text-right">{renderDollarAmount(row.amount)}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer className={clsxm(noData && 'my-auto')}>
      <Table aria-label="Estimated Annual Payroll">
        {!noData && (
          <TableHead>
            <TableRow>
              <TableCell className="text-xl font-medium">
                <span>State</span>
              </TableCell>
              <TableCell className="text-xl font-medium">
                <span>Class Code</span>
              </TableCell>
              <TableCell className="text-right text-xl font-medium">
                <span>Payroll</span>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {noData ? (
            <TableRow>
              <TableCell className="border-none text-center font-medium italic text-gray-500">
                No payroll has been entered; upload an Acord 130 or add payroll on the SafeTier Rating.
              </TableCell>
            </TableRow>
          ) : (
            renderedPayrollEntries
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
