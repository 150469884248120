export const convertNumberToString = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const convertStringToDecimalString = (num: string): string => {
  if (num) {
    const value = num.replace(/\$/g, '').replace(/,/g, ''); // Remove $ and , characters
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      return parsedValue.toFixed(2);
    }
  }

  // Return the original string if it's not a valid number
  return num;
};
