import { useQueryClient } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { FC, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Card } from '@mui/material';
import { format } from 'date-fns';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddAltFilled from '@mui/icons-material/PersonAddAlt';

import { useSubmissionOverviewDetailsForm } from '../hooks/useSubmissionOverviewForm';
import { useEditSubmissionMutation, useUpdateSubmissionOverviewMutation } from '../api/submission.api.hooks';
import { SubmissionOutDto, SubmissionOverviewOutDto } from '@app/swagger-types';
import { SubmissionOverviewDatePicker } from './SubmissionOverviewDatePicker';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { SubmissionAssigneePopover } from './SubmissionAssigneePopover';
import { convertISOToLocalTimeZone } from '@app/utils/date.utils';
import { Input } from '@app/components/fields/input/Input';
import { useHandler } from '@app/hooks/useHandler.hook';
import { FORMAT } from '@app/constants/formats';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { OVERVIEW_DETAILS_NULL_PLACEHOLDERS, SUBMISSION_OVERVIEW_EDITABLE_FIELDS } from '../const/submission.const';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { showErrorToast } from '@app/utils/toast.utils';
import { clsxm } from '@app/styles/clsxm';
import { ReactHookFormPatternField } from '@app/components/fields/react-hook-form-fields/ReactHookFormPatternField';

interface Props {
  data?: SubmissionOutDto;
  overviewData?: SubmissionOverviewOutDto;
  submissionId: string;
}

export type OverviewEditableFields =
  | 'agencyName'
  | 'producerName'
  | 'csRepresentativeName'
  | 'agencyPhone'
  | 'agencyEmail';

export const SubmissionOverviewDetails: FC<Props> = ({ data, overviewData, submissionId }) => {
  const queryClient = useQueryClient();

  const { reset, control, formState, setValue } = useSubmissionOverviewDetailsForm();

  const watch = useWatch({ control });

  const { mutateAsync: updateOverview, isLoading: isUpdatingOverview } = useUpdateSubmissionOverviewMutation(
    submissionId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_OVERVIEW, submissionId] });
      },
    }
  );

  const { mutate: updateSubmission, isLoading } = useEditSubmissionMutation(submissionId, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_BY_ID, submissionId] });
    },
  });

  const [editingField, setEditingField] = useState<OverviewEditableFields | null>(null);

  const handleEndEditing = useHandler(async (field: OverviewEditableFields | null) => {
    if (editingField) {
      setValue(field as OverviewEditableFields, overviewData?.[field as OverviewEditableFields] || '');
    }

    setEditingField(null);
  });

  const handleSaveEditing = useHandler(async () => {
    if (editingField && !Boolean(formState?.errors?.[editingField as OverviewEditableFields])) {
      await updateOverview({ [editingField]: watch[editingField] });

      setEditingField(null);
    } else {
      showErrorToast(formState?.errors?.[editingField as OverviewEditableFields]?.message);
    }
  });

  const handleEditStart = useHandler((field: OverviewEditableFields | null) => setEditingField(field));

  useEffect(() => {
    if (overviewData) {
      reset({
        agencyName: overviewData.agencyName || '',
        producerName: overviewData.producerName || '',
        csRepresentativeName: overviewData.csRepresentativeName || '',
        agencyPhone: overviewData.agencyPhone || '',
        agencyEmail: overviewData.agencyEmail || '',
      });
    }
  }, [reset, overviewData]);

  const renderInputEndAdornment = (field: OverviewEditableFields) => (
    <div className="flex items-center gap-1">
      <Tooltip
        placement="top"
        title={
          Boolean(formState?.errors?.[field as OverviewEditableFields]) ? "Can't save until not valid" : 'Save Changes'
        }
      >
        <IconButton
          id={`${field}-input-adornment-save`}
          className="text-gray-500"
          onClick={handleSaveEditing}
          size="medium"
        >
          <CheckIcon />
        </IconButton>
      </Tooltip>
      <Tooltip placement="top" title="Discard Changes">
        <IconButton
          id={`${field}-input-adornment-close`}
          className="text-gray-500"
          size="medium"
          onClick={(e) => {
            e.stopPropagation();

            handleEndEditing(field);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  const handleBlur = useHandler((e, field) => {
    if (
      e.relatedTarget?.id !== `${field}-input-adornment-save` &&
      e.relatedTarget?.id !== `${field}-input-adornment-close`
    ) {
      handleEndEditing(field);
    }
  });

  return (
    <Card className="flex h-fit w-full flex-col gap-2.5 overflow-x-auto rounded-lg border border-[#0000001F] bg-white px-6 py-10 shadow-card">
      <h2 className="text-xxxl">Details</h2>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <span className="text-xxs text-gray-500">Assignee</span>
          {data?.assignee ? (
            <div className="group flex items-center gap-2">
              <SubmissionAssigneePopover
                className="m-0 break-words px-2 text-m shadow-assigneeBtn"
                text={data?.assigneeName || ''}
                submissionId={submissionId}
                value={data?.assignee}
              />
            </div>
          ) : (
            <div className="flex items-center">
              <SubmissionAssigneePopover
                className="m-0 break-words text-m text-gray-400 shadow-assigneeBtn hover:shadow-assigneeBtn"
                submissionId={submissionId}
                icon={<PersonAddAltFilled />}
                value={''}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-xxs text-gray-500">Received</span>
          <span className="break-words text-m font-normal text-[#00000099]">
            {data?.createdDate ? format(convertISOToLocalTimeZone(data?.createdDate), FORMAT.DATE_YEAR) : '-'}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-xxs text-gray-500">Need By Date</span>
          <SubmissionOverviewDatePicker
            onChange={(date) => updateSubmission({ neededByDate: date.toISOString() })}
            disabled={isUpdatingOverview || isLoading}
            effectiveDate={data?.effectiveDate}
            value={data?.neededByDate}
          />
        </div>
        {SUBMISSION_OVERVIEW_EDITABLE_FIELDS.map(({ field, label }) => (
          <div
            onClick={() => handleEditStart(field as OverviewEditableFields)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleEditStart(field as OverviewEditableFields);
              }
            }}
            className="group flex flex-col gap-2"
            key={field}
          >
            <span className="text-xxs text-gray-500">{label}</span>
            {editingField === field ? (
              <>
                {field === 'agencyPhone' ? (
                  <ReactHookFormPatternField
                    errorMessage={formState.errors.agencyPhone?.message}
                    onFieldBlur={(e) => handleBlur(e, 'agencyPhone')}
                    endAdornment={renderInputEndAdornment(field)}
                    disabled={isUpdatingOverview}
                    placeholder="(NNN) NNN-NNNN"
                    format="(###) ###-####"
                    name="agencyPhone"
                    useFormattedValue
                    control={control}
                    size="small"
                    fullWidth
                    mask="_"
                  />
                ) : (
                  <Controller
                    render={(item) => (
                      <Input
                        {...item.field}
                        errorMessage={formState?.errors?.[item.field.name as OverviewEditableFields]?.message}
                        endAdornment={renderInputEndAdornment(field)}
                        onBlur={(e) => handleBlur(e, field)}
                        disabled={isUpdatingOverview}
                      />
                    )}
                    control={control}
                    name={field}
                  />
                )}
              </>
            ) : (
              <div className="flex h-8 items-center gap-2">
                <span
                  className={clsxm(
                    'truncate text-l',
                    !watch[field as OverviewEditableFields] && 'italic text-gray-400'
                  )}
                >
                  {watch[field as OverviewEditableFields] ||
                    OVERVIEW_DETAILS_NULL_PLACEHOLDERS[field as OverviewEditableFields]}
                </span>
                <EditIcon className="hidden text-gray-500 group-hover:flex" fontSize="small" />
              </div>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
};
