import { useFormContext, useWatch } from 'react-hook-form';
import { FC } from 'react';

import { SubmissionOverviewProfileFormSchemaType } from '../schemas/submission.form';
import { NaicsOutDto, SubmissionOverviewOutDto } from '@app/swagger-types';
import { MIN_OPERATION_STATES_CHARACTERS_TO_FLEX, OVERVIEW_PROFILE_NULL_PLACEHOLDERS } from '../const/submission.const';
import { clsxm } from '@app/styles/clsxm';

interface Props {
  overviewData?: SubmissionOverviewOutDto;
  naics?: NaicsOutDto[];
}

export const SubmissionOverviewProfileView: FC<Props> = ({ overviewData, naics }) => {
  const { control } = useFormContext<SubmissionOverviewProfileFormSchemaType>();

  const { domiciledState, operationStates, operationalNotes, exModFactor, hazardGrade, governingClass } = useWatch({
    control,
  });

  const naicsItem = naics?.find((item) => item.naics === overviewData?.naics) || null;

  return (
    <div className="grid grid-cols-1 gap-4 4xl:grid-cols-[65fr_35fr] 4xl:gap-20">
      <div className="flex flex-col gap-8">
        <div
          className={clsxm(
            'grid gap-4 md:grid-cols-2 lg:justify-between',
            (operationStates?.length || 0) >= MIN_OPERATION_STATES_CHARACTERS_TO_FLEX
              ? 'lg:grid-cols-[minmax(90px,120px)_1fr_minmax(110px,120px)_90px]'
              : 'lg:grid-cols-[minmax(90px,120px)_120px_minmax(110px,120px)_90px]'
          )}
        >
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">Dom State</span>
            <span className={clsxm('text-l', !domiciledState && 'italic text-gray-400')}>
              {domiciledState || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.domiciledState}
            </span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">States of Operation</span>
            <span className={clsxm('text-l', !operationStates && 'italic text-gray-400')}>
              {operationStates || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.operationStates}
            </span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">EMR</span>
            <span className={clsxm('text-l', !exModFactor && 'italic text-gray-400')}>
              {exModFactor || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.exModFactor}
            </span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">Haz Grade</span>
            <span className={clsxm('text-l', !hazardGrade && 'italic text-gray-400')}>
              {hazardGrade || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.hazardGrade}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">Gov Class</span>
            <span className={clsxm('text-l', !governingClass && 'italic text-gray-400')}>
              {governingClass || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.governingClass}
            </span>
          </div>
          <div className="flex flex-col justify-start gap-2">
            <span className="text-xxs text-gray-500">NAICS</span>
            <span className={clsxm('text-l', !naicsItem && 'italic text-gray-400')}>
              {naicsItem ? `${naicsItem.naics} - ${naicsItem.description}` : OVERVIEW_PROFILE_NULL_PLACEHOLDERS.naics}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start gap-2 4xl:gap-2">
        <h3 className="text-xxs text-gray-500">Operational Notes</h3>
        <p className={clsxm('text-l', !operationalNotes && 'italic text-gray-400')}>
          {operationalNotes || OVERVIEW_PROFILE_NULL_PLACEHOLDERS.operationalNotes}
        </p>
      </div>
    </div>
  );
};
