import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { EQueryConfigName } from '@app/constants/query-config.const';
import { OverrideSubmissionStiAwsPrefillOutDto, ServerError } from '@app/swagger-override-types';
import {
  PageResponseSubmissionNamedInsuredOutDto,
  SubmissionStiFullPrefillOutDto,
  SubmissionStiFullPrefillInDto,
  SubmissionStiAwsPrefillInDto,
  PageResponseSubmissionOutDto,
  SubmissionNamedInsuredOutDto,
  SubmissionVerificationOutDto,
  SubmissionNamedInsuredInDto,
  SubmissionLossSummaryOutDto,
  SubmissionLossReviewOutDto,
  SubmissionInsightOutDto,
  SubmissionGiCalcOutDto,
  SubmissionGiCalcInDto,
  SubmissionFileOutDto,
  PresignedUrlOutDto,
  SubmissionPatchDto,
  AbstractCalcOutDto,
  CalcErrorResponse,
  SubmissionOutDto,
  SubmissionInDto,
  ErrorResponse,
  EOutcomeType,
  SubmissionRiskFactorsOutDto,
  SubmissionRiskFactorInDto,
  RiskFactorOutDto,
  SubmissionRiskFactorOutDto,
  SubmissionOverviewOutDto,
  SubmissionOverviewInDto,
} from '@app/swagger-types';
import { PaginationQueryParams } from '@app/types/query-params.types';
import {
  FullCalcOutDtoResponse,
  AwsCalcOutDtoResponse,
  FullCalcInDto,
  AwsCalcInDto,
} from '@app/domain/sti/types/calc.types';
import {
  UploadSubmissionWorkbookInDto,
  ReplaceSubmissionFileInDto,
  BaseSubmissionFileInDto,
  GetSubmissionFileParams,
  SubmissionGiCalcParams,
  SubmissionFileInDto,
  SubmissionApi,
  SubmissionsParams,
  CheckSubmissionDuplicateParams,
} from './submission.api';
import { showErrorToast, showSuccessToast } from '@app/utils/toast.utils';
import { StiParams } from '@app/domain/sti/api/sti-api';
import { EMutationConfigName } from '@app/constants/mutation-config.const';

export const useGetSubmissionsQuery = (
  params: SubmissionsParams,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    PageResponseSubmissionOutDto,
    (typeof EQueryConfigName.GET_SUBMISSIONS | SubmissionsParams)[]
  >
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_SUBMISSIONS, params],
    queryFn: async () => {
      return await SubmissionApi.getSubmissions(params);
    },
    ...options,
  });
};

export const useGetSubmissionNamedInsuredsQuery = (
  params: PaginationQueryParams,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    PageResponseSubmissionNamedInsuredOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_NAMED_INSUREDS | PaginationQueryParams)[]
  >
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_SUBMISSION_NAMED_INSUREDS, params],
    queryFn: async () => {
      return await SubmissionApi.getNamedInsureds(params);
    },
    ...options,
  });
};

export const useGetSubmissionByIdQuery = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_BY_ID | typeof submissionId)[]
  >
) => {
  return useQuery({
    keepPreviousData: true,
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_BY_ID, submissionId],
    queryFn: async () => {
      return await SubmissionApi.getSubmissionById(submissionId);
    },
  });
};

export const useGetSubmissionLossReviewQuery = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionLossReviewOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_LOSS_REVIEW | typeof submissionId)[]
  >
) => {
  return useQuery({
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_LOSS_REVIEW, submissionId],
    queryFn: async () => {
      return await SubmissionApi.getSubmissionLossReview(submissionId);
    },
  });
};

export const useGetSubmissionLossSummaryQuery = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionLossSummaryOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_LOSS_SUMMARY | typeof submissionId)[]
  >
) => {
  return useQuery({
    keepPreviousData: true,
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_LOSS_SUMMARY, submissionId],
    queryFn: async () => {
      return await SubmissionApi.getSubmissionLossSummary(submissionId);
    },
  });
};

export const useGetSubmissionFileMutation = (
  options?: UseMutationOptions<SubmissionFileOutDto, ServerError, BaseSubmissionFileInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (payload) => {
      return await SubmissionApi.getSubmissionFileById(payload);
    },
  });
};

export const useCreateSubmissionMutation = (
  options?: UseMutationOptions<SubmissionOutDto, ServerError, SubmissionInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.createSubmission(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSIONS]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};
export const useEditSubmissionMutation = <TContext = unknown>(
  submissionId: string,
  options?: UseMutationOptions<SubmissionOutDto, ServerError, SubmissionPatchDto, TContext>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.editSubmission(submissionId, dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSIONS]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreateNamedInsuredMutation = (
  options?: UseMutationOptions<SubmissionNamedInsuredOutDto, ServerError, SubmissionNamedInsuredInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.createNamedInsured(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_NAMED_INSUREDS]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

// use as 'mutationKey' for 'useUploadSubmissionFileMutation'
export enum EUploadSubmissFileMutationKey {
  UPLOAD_ACCORD = 'UPLOAD_ACCORD',
  UPLOAD_EMR = 'UPLOAD_EMR',
  UPLOAD_LOSS_RUNS = 'UPLOAD_LOSS_RUNS',
}

export const useUploadSubmissionFileMutation = (
  options?: UseMutationOptions<unknown, ServerError, SubmissionFileInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.uploadFileToSubmission(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, variables.submissionId]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useReuploadSubmissionFilesMutation = (
  options?: UseMutationOptions<unknown, ServerError, ReplaceSubmissionFileInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.reuploadFileToSubmission(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, variables.submissionId]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteSubmissionFilesMutation = (
  options?: UseMutationOptions<unknown, ServerError, BaseSubmissionFileInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.deleteFileToSubmission(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, variables.submissionId]);

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDownloadSubmissionUrlMutation = (
  options?: UseMutationOptions<PresignedUrlOutDto, ServerError, string | number>
) => {
  return useMutation({
    ...options,
    mutationFn: async (submissionId) => {
      return await SubmissionApi.getSubmissionDownloadUrl(submissionId);
    },
    onSuccess: async (data, variables, context) => {
      if (data.downloadUrl) {
        window.location.href = data.downloadUrl;
      } else {
        showErrorToast('File not found');
      }

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUploadSubmissionWorkbookMutation = (
  options?: UseMutationOptions<unknown, ServerError, UploadSubmissionWorkbookInDto>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (dto) => {
      return await SubmissionApi.uploadSubmissionWorkbook(dto);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_BY_ID, variables.submissionId]);

      await options?.onSuccess?.(data, variables, context);

      showSuccessToast('File successfully uploaded');
    },
  });
};

export const useDownloadSubmissionFileUrlMutation = (
  options?: UseMutationOptions<PresignedUrlOutDto, ServerError, GetSubmissionFileParams>
) => {
  return useMutation({
    ...options,
    mutationFn: async (params) => {
      return await SubmissionApi.getFileDownloadUrl(params);
    },
    onSuccess: async (data, variables, context) => {
      if (data.downloadUrl) {
        window.location.href = data.downloadUrl;
      } else {
        showErrorToast('File not found');
      }

      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCalculateSubmissionBySti = (
  submissionId: string,
  params: StiParams,
  options?: UseMutationOptions<FullCalcOutDtoResponse, ErrorResponse | CalcErrorResponse, FullCalcInDto>
) => {
  return useMutation({
    ...options,
    mutationKey: [EMutationConfigName.CALC_SUBMISSION_BY_STI, submissionId],
    mutationFn: async (dto) => await SubmissionApi.calculateSubmissionBySti(submissionId, dto, params),
    onSuccess: async (data, variables, context) => {
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCalculateSubmissionByAws = (
  submissionId: string,
  options?: UseMutationOptions<AwsCalcOutDtoResponse, CalcErrorResponse, AwsCalcInDto>
) => {
  return useMutation({
    ...options,
    mutationKey: [EMutationConfigName.CALC_SUBMISSION_BY_AWS, submissionId],
    mutationFn: async (dto) => await SubmissionApi.calculateSubmissionByAws(submissionId, dto),
    onSuccess: async (data, variables, context) => {
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetSubmissionStiFullPrefill = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionStiFullPrefillOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_STI_FULL_PREFILL | typeof submissionId)[]
  >
) => {
  return useQuery({
    keepPreviousData: true,
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_STI_FULL_PREFILL, submissionId],
    queryFn: async () => await SubmissionApi.getSubmissionStiFullPrefill(submissionId),
  });
};

export const useGetSubmissionStiAwsPrefill = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    OverrideSubmissionStiAwsPrefillOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_STI_AWS_PREFILL | typeof submissionId)[]
  >
) => {
  return useQuery({
    enabled: Boolean(submissionId),
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_STI_AWS_PREFILL, submissionId],
    queryFn: async () => await SubmissionApi.getSubmissionStiAwsPrefill(submissionId),
  });
};

export const useUpdateSubmissionStiFullPrefill = (
  submissionId: string,
  options?: UseMutationOptions<SubmissionStiFullPrefillOutDto, ServerError, SubmissionStiFullPrefillInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (dto) => await SubmissionApi.updateSubmissionStiFullPrefill(submissionId, dto),
  });
};

export const useUpdateSubmissionStiAwsPrefill = (
  submissionId: string,
  options?: UseMutationOptions<OverrideSubmissionStiAwsPrefillOutDto, ServerError, SubmissionStiAwsPrefillInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (dto) => await SubmissionApi.updateSubmissionStiAwsPrefill(submissionId, dto),
  });
};

export const useGetSubmissionStiOutcome = (
  submissionId: string,
  outcomeType: EOutcomeType,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    AbstractCalcOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_STI_OUTCOME | typeof submissionId)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_STI_OUTCOME, submissionId, outcomeType],
    queryFn: async () => await SubmissionApi.getSubmissionStiOutcome(submissionId, { outcomeType }),
  });
};

export const useCalcSubmissionGi = (
  options?: UseMutationOptions<
    SubmissionGiCalcOutDto,
    ServerError,
    { dto: SubmissionGiCalcInDto; params?: SubmissionGiCalcParams }
  >
) => {
  return useMutation({
    ...options,
    mutationKey: [EMutationConfigName.CALC_SUBMISSION_BY_GI_DATA],
    mutationFn: async ({ dto, params }) => await SubmissionApi.calculateGi(dto, params),
  });
};

export const useGetSubmissionInsights = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionInsightOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_INSIGHTS | typeof submissionId)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_INSIGHTS, submissionId],
    queryFn: async () => await SubmissionApi.getSubmissionInsights(submissionId),
  });
};

export const useGenerateSubmissionInsights = (
  submissionId: string,
  options?: UseMutationOptions<SubmissionInsightOutDto, ServerError>
) => {
  return useMutation({
    ...options,
    mutationKey: [EMutationConfigName.GENERATE_SUBMISSION_INSIGHTS, submissionId],
    mutationFn: async () => await SubmissionApi.generateSubmissionInsights(submissionId),
  });
};

export const useGetSubmissionVerified = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionVerificationOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_VERIFIED | typeof submissionId)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_VERIFIED, submissionId],
    queryFn: async () => await SubmissionApi.getSubmissionVerified(submissionId),
  });
};

export const useCheckSubmissionDuplicateMutation = (
  options?: UseMutationOptions<boolean, ServerError, CheckSubmissionDuplicateParams>
) => {
  return useMutation({
    ...options,
    mutationFn: async (params) => await SubmissionApi.checkSubmissionDuplicate(params),
  });
};

export const useGetRiskFactorsBySubmissionIdQuery = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionRiskFactorsOutDto,
    (typeof EQueryConfigName.GET_RISK_FACTORS_BY_SUBMISSION_ID | typeof submissionId)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_RISK_FACTORS_BY_SUBMISSION_ID, submissionId],
    queryFn: async () => await SubmissionApi.getRiskFactorsBySubmissionId(submissionId),
  });
};

export const useGetAllSubmissionRiskFactorsQuery = (
  options?: UseQueryOptions<
    unknown,
    ServerError,
    RiskFactorOutDto[],
    (typeof EQueryConfigName.GET_SUBMISSION_RISK_FACTORS)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_RISK_FACTORS],
    queryFn: async () => await SubmissionApi.getAllSubmissionRiskFactors(),
  });
};

export const useUpdateSubmissionRiskFactorsMutation = (
  submissionId: string,
  options?: UseMutationOptions<
    SubmissionRiskFactorsOutDto,
    ServerError,
    SubmissionRiskFactorInDto[],
    { previousData?: SubmissionRiskFactorsOutDto }
  >
) => {
  const queryClient = useQueryClient();

  const QUERY_KEY = [EQueryConfigName.GET_RISK_FACTORS_BY_SUBMISSION_ID, submissionId];

  return useMutation<
    SubmissionRiskFactorsOutDto,
    ServerError,
    SubmissionRiskFactorInDto[],
    { previousData?: SubmissionRiskFactorsOutDto }
  >({
    ...options,
    mutationFn: async (dto) => await SubmissionApi.updateSubmissionRiskFactors(submissionId, dto),
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: QUERY_KEY });

      // Snapshot the previous value
      const previousData = queryClient.getQueryData<SubmissionRiskFactorsOutDto>(QUERY_KEY);

      // Optimistically update to the new value
      if (previousData) {
        const newRisks: SubmissionRiskFactorOutDto[] = previousData.risks.map((prevRisk) => {
          const updatedItem = variables.find((newRisk) => newRisk.id === prevRisk.id);

          if (updatedItem) {
            return {
              ...prevRisk,
              ...updatedItem,
              riskValue: updatedItem.riskFactorValue,
            };
          }

          return prevRisk;
        });
        queryClient.setQueryData<SubmissionRiskFactorsOutDto>(QUERY_KEY, {
          ...previousData,
          risks: newRisks,
        });
      }

      await options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousData };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: async (error, variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(QUERY_KEY, context.previousData);
      }
      await options?.onError?.(error, variables, context);
    },
    // Always refetch after error or success:
    onSettled: async (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY });
      await options?.onSettled?.(data, error, variables, context);
    },
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [EQueryConfigName.GET_SUBMISSION_STI_FULL_PREFILL, submissionId] });
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetSubmissionOverviewQuery = (
  submissionId: string,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    SubmissionOverviewOutDto,
    (typeof EQueryConfigName.GET_SUBMISSION_OVERVIEW | typeof submissionId)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_SUBMISSION_OVERVIEW, submissionId],
    queryFn: async () => await SubmissionApi.getSubmissionOverview(submissionId),
  });
};

export const useUpdateSubmissionOverviewMutation = (
  submissionId: string,
  options?: UseMutationOptions<SubmissionOverviewOutDto, ServerError, SubmissionOverviewInDto>
) => {
  return useMutation({
    ...options,
    mutationKey: [EMutationConfigName.UPDATE_SUBMISSION_OVERVIEW, submissionId],
    mutationFn: async (dto) => await SubmissionApi.updateSubmissionOverview(submissionId, dto),
  });
};
