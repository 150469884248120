import { useState, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Typography, Menu, MenuItem } from '@mui/material';

import { AuthUser } from '@app/auth/api/auth.dto';
import { useStore } from '@app/store/useStore.hook';
import { clsxm } from '@app/styles/clsxm';
import { ReactComponent as LogoutIconSvg } from '@app/assets/icons/logout-icon.svg';
import { ConditionalWrapper } from '@app/hoc';
import { Button } from '@app/components/buttons/button/Button';
import { useHandler } from '@app/hooks/useHandler.hook';
import { CircularProgress } from '@app/components/CircularProgress';
import { Avatar } from '@app/components/avatar/Avatar';

import styles from './UserHeaderInfo.module.scss';

type InjectedProps = {
  user: AuthUser;
  isMobileLayout?: boolean;
};

export const UserHeaderInfo: React.FC<InjectedProps> = ({ user, isMobileLayout }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const queryClient = useQueryClient();
  const { signOut, isSigningOut } = useStore();

  const username = useMemo(() => {
    const { username, email, firstName, lastName } = user;

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }

    return email || username;
  }, [user]);

  const handleClick = useHandler((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  });

  const handleClose = useHandler(() => {
    setAnchorEl(null);
  });

  const handleLogout = useHandler(async () => {
    await signOut({ srcUrl: window.location.href });

    queryClient.removeQueries();
  });

  return (
    <div className={styles.Container} data-cy="user-header-avatar">
      <Menu
        elevation={0}
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem
          className="flex items-center justify-end gap-4 !bg-white px-4 py-0.5 text-gray-400 hover:text-yellow-500"
          disabled={isSigningOut}
          onClick={handleLogout}
        >
          {isSigningOut ? (
            <>
              <CircularProgress size={20} />
              Signing Out
            </>
          ) : (
            <>
              <LogoutIconSvg />
              Sign Out
            </>
          )}
        </MenuItem>
      </Menu>
      <Button
        variant="text"
        disableRipple
        className={clsxm(styles.MenuButton, 'space-x-2 text-gray-400 hover:text-gray-700')}
        onClick={handleClick}
      >
        <ConditionalWrapper
          condition={!!isMobileLayout}
          wrapper={(children) => {
            return <div className={styles.NameGroup}>{children}</div>;
          }}
        >
          <Typography variant="m" noWrap className={styles.UserName}>
            {username}
          </Typography>
        </ConditionalWrapper>
        <Avatar className={styles.Avatar} id="header-avatar-filter" width={32} height={32} />
      </Button>
    </div>
  );
};
