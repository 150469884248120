import { ESubmissionFileStatus } from '@app/swagger-types';
import { OverviewEditableFields } from '../components/SubmissionOverviewDetails';

export const LOADING_ESubmissionFileStatus_VALUES: ESubmissionFileStatus[] = [
  ESubmissionFileStatus.PROCESSING,
  ESubmissionFileStatus.POLLING_FOR_RESULT,
  ESubmissionFileStatus.DOWNLOADING_RESPONSE,
  ESubmissionFileStatus.CHECKING_RESPONSE,
  ESubmissionFileStatus.UPDATING_RESULTS,
];

export const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const SUBMISSION_OVERVIEW_EDITABLE_FIELDS = [
  { field: 'agencyName', label: 'Agency' },
  { field: 'producerName', label: 'Producer' },
  { field: 'csRepresentativeName', label: 'CSR/AM' },
  { field: 'agencyPhone', label: 'Agency Office Phone' },
  { field: 'agencyEmail', label: 'Agency Contact Email' },
];

export const GOVERNING_CLASS_REGEXP = /^([A-Z]{2})\/?(\d{4})$/;
export const GOVERNING_CLASS_VALUE_REGEXP = /\b[A-Z]{2}\s?\d{4}\b/;

export const OVERVIEW_PROFILE_NULL_PLACEHOLDERS = {
  domiciledState: 'Select State',
  operationStates: 'Select States',
  exModFactor: 'Enter EMR',
  hazardGrade: 'Select Gov Class',
  governingClass: 'Select Gov Class',
  naics: 'Select NAICS',
  operationalNotes: 'Enter notes',
};

export const OVERVIEW_DETAILS_NULL_PLACEHOLDERS: Record<OverviewEditableFields, string> = {
  agencyName: 'Enter Agency',
  producerName: 'Enter Producer',
  csRepresentativeName: 'Enter  CSR/AM',
  agencyPhone: 'Enter Phone Number',
  agencyEmail: 'Enter Email Address',
};

export const MIN_OPERATION_STATES_CHARACTERS_TO_FLEX = 30;
