import { Card } from '@mui/material';
import { FC } from 'react';

import { SubmissionOverviewPayrollTable } from './SubmissionOverviewPayrollTable';
import { NcciOutDto, SubmissionOverviewOutDto } from '@app/swagger-types';
import { renderDollarAmount } from '@app/utils/currency/currency.utils';

interface Props {
  overviewData?: SubmissionOverviewOutDto;
  ncci: NcciOutDto[];
}

export const SubmissionOverviewEstimatedAnnualPayroll: FC<Props> = ({ overviewData, ncci }) => {
  const estimatedAnnualPayroll =
    overviewData?.payrollEntries?.reduce((acc, curr) => (acc += curr?.amount || 0), 0) || 0;

  return (
    <Card className="flex w-full grow flex-col gap-2.5 rounded-lg border border-[#0000001F] bg-white px-6 py-10 shadow-card">
      <div className="flex items-center justify-between gap-2">
        <h2 className="text-xxxl">Estimated Annual Payroll</h2>
        <h3 className="pr-4 text-xxl">
          {overviewData?.payrollEntries?.length === 0 ? '' : renderDollarAmount(estimatedAnnualPayroll)}
        </h3>
      </div>
      <SubmissionOverviewPayrollTable ncci={ncci} overviewData={overviewData} />
    </Card>
  );
};
