import { divide } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { NumericFormat } from 'react-number-format';

import { Button } from '@app/components/buttons/button/Button';
import { Input } from '@app/components/fields/input/Input';
import { TrirDartTable } from './TrirDartTable';
import { SubmissionLossSummaryOutDto } from '@app/swagger-types';
import { useEditSubmissionMutation } from '../api/submission.api.hooks';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useQueryClient } from '@tanstack/react-query';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { useSubmissionLossSummaryContext } from '../contexts/SubmissionLossSummaryContext';
import { convertStringToDecimalString } from '@app/utils/number.utils';

interface Props {
  open: boolean;
  submissionId: string;
  lossSummaryData: SubmissionLossSummaryOutDto | undefined;
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
}

export const LossSummaryPopover: FC<Props> = ({ lossSummaryData, anchorEl, onClose, submissionId, open }) => {
  const { onResetForm } = useSubmissionLossSummaryContext();
  const queryClient = useQueryClient();
  const [averageHourlyWage, setAverageHourlyWage] = useState(
    convertStringToDecimalString(lossSummaryData?.averageHourlyWage.toString() || '0')
  );

  const { mutate: updateSubmission, isLoading: isUpdating } = useEditSubmissionMutation(submissionId, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_LOSS_SUMMARY, submissionId]);
      await queryClient.invalidateQueries([EQueryConfigName.GET_SUBMISSION_STI_FULL_PREFILL, submissionId]);
      onClose();

      // TODO: Remove setTimeout. It is currently used to reset the form with actual data. Without setTimeout, the form resets to outdated values.
      setTimeout(onResetForm, 200);
    },
  });

  const id = open ? 'loss-summary-popover' : undefined;

  const totalPayroll = lossSummaryData?.totalPayroll || 0;

  const totalHoursWorked = averageHourlyWage ? Number(divide(totalPayroll, Number(averageHourlyWage)).toFixed(2)) : 0;

  useEffect(() => {
    setAverageHourlyWage(convertStringToDecimalString(lossSummaryData?.averageHourlyWage.toString() || '0'));
  }, [lossSummaryData?.averageHourlyWage]);

  const handleSave = useHandler(() => updateSubmission({ averageHourlyWage: Number(averageHourlyWage) }));

  const handleClose = useHandler(() => {
    onClose();
    setAverageHourlyWage(convertStringToDecimalString(lossSummaryData?.averageHourlyWage.toString() || '0'));
  });

  return (
    <Popover
      id={id}
      open={open || isUpdating}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className="mt-3"
    >
      <div className="flex w-[540px] flex-col gap-1.5 p-6">
        <div className="flex gap-2 border-b border-primary p-2">
          <NumericFormat
            customInput={Input}
            value={totalPayroll}
            label="Total Payroll"
            thousandSeparator
            prefix="$"
            readOnly
            classes={{ root: 'border-0' }}
          />
          <NumericFormat
            customInput={Input}
            value={averageHourlyWage}
            onValueChange={({ value }) => {
              setAverageHourlyWage(value);
            }}
            onBlur={(e) => {
              setAverageHourlyWage(convertStringToDecimalString(e.target.value));
            }}
            label="Average Hourly Wage"
            thousandSeparator
            prefix="$"
            decimalScale={2}
            disabled={isUpdating}
            allowNegative={false}
          />
          <NumericFormat
            customInput={Input}
            value={totalHoursWorked}
            label="Total Hours Worked"
            thousandSeparator
            readOnly
            classes={{ root: 'border-0' }}
          />
        </div>
        {lossSummaryData ? (
          <TrirDartTable estHoursWorked={Number(averageHourlyWage)} lossSummaryData={lossSummaryData} />
        ) : null}
        <div className="flex items-center justify-end gap-2 p-4">
          <Button variant="outlined" size="small" onClick={handleClose} disabled={isUpdating} className="rounded-md">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            size="small"
            disabled={isUpdating}
            className="rounded-md border-secondary bg-secondary text-white disabled:opacity-70"
          >
            Save
          </Button>
        </div>
      </div>
    </Popover>
  );
};
